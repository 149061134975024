import React, {useState} from "react";
import { graphql } from 'gatsby'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate } from 'gatsby';

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import EmailCard from "../components/EmailCard"
import TemplatePreview from "../components/TemplatePreview"
import SubTemplatePageLayout from "../components/SubTemplatePageLayout"


import {
  Grid,
  Link,
} from '@mui/material';

import * as helpers from "../helpers"
import CONSTS from "../consts"

const HtmlReviewRequestEmailPage = (props) => {
  const [previewingTemplateIndex, setPreviewingTemplateIndex] = useState(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const keyCheck = React.useCallback((event) => {
    const keyCode = event.keyCode; 

    if (previewDialogOpen) {
      if (keyCode === 37) {
        onPreviewLastClicked();
      } else if (keyCode === 39) {
        onPreviewNextClicked();
      }
    }
  },[previewDialogOpen, previewingTemplateIndex]);

  React.useEffect(() => {
    window.addEventListener('keyup', keyCheck);

    return () => window.removeEventListener('keyup', keyCheck);
  }, [keyCheck])

  const templates = helpers.normalizeTemplatesData(props.data.emailTemplates.nodes);

  const nextDisabled = previewingTemplateIndex === templates.length - 1;
  const lastDisabled = previewingTemplateIndex === 0;

  const onPreviewLastClicked = () => {
    if (!lastDisabled) {
      setPreviewingTemplateIndex(previewingTemplateIndex - 1);
    }
  };

  const onPreviewNextClicked = () => {
    if (!nextDisabled) {
      setPreviewingTemplateIndex(previewingTemplateIndex + 1)
    }
  };

  return (
    <Layout>
      <SubTemplatePageLayout
        header='HTML Email Templates'
        headerUrl='html_email_header.jpg'
        subHead='Eye catching, professional email templates that motivate customers to post reviews.'
        textContent={<>
          Welcome to our collection of HTML review request email templates!
          <br/><br/>
          Here you'll find a variety of options to choose from, each is designed to encourage customers to leave valuable feedback.
          <br/><br/>
          Whether you're looking for a simple approach, or something a bit more creative and engaging, we've got you covered.
          <br/><br/><br/><br/>
          Simply scroll through our templates to find the perfect one for your business.
          <br/><br/>
          When you find one you like, click on it. Doing so will open a larger preview that provides more information, including how to use it.
        </>}
        moreTextContent={<>
          Each email includes a direct link to the review page and clear instructions to make it easy for customers to leave a review.
          <br/><br/>
          Every template on the site is free to use and available for download without an account.
          <br/><br/>
          Our templates are compatible with modern email applications and built to look great on all screen sizes, mobile to desktop.
          <br/><br/>
          No need to hire a developer. Just follow our simple <Link href='/articles/review-request-email-templates#html-templates' title='Instructions for sending one of these HTML template'>sending instructions</Link>. Anyone can do it. No coding skills required.
          <br/><br/>
          With our review request emails, you'll be able to gather valuable feedback and improve your business in no time!
        </>}
        templatesLength={templates.length}
      >
        <Grid container spacing={4}>
          {templates.map((template, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <EmailCard
                template={template}
                selected={previewingTemplateIndex === index}
                templateLink={CONSTS.PATHS.INDIVIDUAL_HTML_EMAIL_TEMPLATE.replace('{name}', template.id)}
                onPreviewTemplate={() => {
                  if (smallScreen) {
                    navigate(CONSTS.PATHS.INDIVIDUAL_HTML_EMAIL_TEMPLATE.replace('{name}', template.id))
                  } else {
                    setPreviewingTemplateIndex(index);
                    setPreviewDialogOpen(true);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
        <TemplatePreview
          individualPagePath={CONSTS.PATHS.INDIVIDUAL_HTML_EMAIL_TEMPLATE}
          template={previewingTemplateIndex !== null ? templates[previewingTemplateIndex] : null}
          open={previewDialogOpen}
          onClose={() => {
            setPreviewDialogOpen(false);
          }}
          nextDisabled={nextDisabled}
          lastDisabled={lastDisabled}
          onLastClicked={() => onPreviewLastClicked()}
          onNextClicked={() => onPreviewNextClicked()}
          visualType='IMAGE'
        />
      </SubTemplatePageLayout>
    </Layout>
  );
}

export const Head = ({location, data}) => <Seo
  title={`HTML Review Request Email Templates | Over ${(data.emailTemplates.nodes.length - 1)} templates to choose from!`}
  description='Discover effective ways to gather customer feedback with our review request email templates. Choose from a variety of options and start collecting valuable insights today! No Account Required.'
  url={location.pathname ? location.pathname : null}
/>

export const query = graphql`
  query allTemplateQuery {
    emailTemplates: allDirectory(sort: {order: ASC, fields: relativePath}, filter: {sourceInstanceName: {eq: "emailTemplates"}, relativeDirectory: {eq: ""}}) {
      nodes {
        relativePath
        childFiles {
          name
          childTemplateData {
            description
            variables {
              description
              variable
              example
            }
          }
          childPlainText {
            content
          }
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;



export default HtmlReviewRequestEmailPage
