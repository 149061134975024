/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import {
  Box,
  Typography,
  Link
} from '@mui/material';


const SubTemplatePageLayout = ({ header, headerUrl, subHead, textContent, moreTextContent, templatesLength, children }) => {
  const [moreText, setMoreText] = React.useState(false);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#eaeaea',
          backgroundImage: `url("/${headerUrl}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#ffffffd4',
            py: 10,
            px: 4,
          }}
        >
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              textAlign: 'center',
              fontSize: ['2.6rem', '4rem'],
            }}
          >
            {header}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            sx={{
              color: '##535353',
            }}
          >
            {subHead}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          py: 6,
          px: [1, 2, 2, 4],
          background: '#f7f7f7',
        }}
      >
        <Typography
          variant="p"
        >
          {textContent}
        </Typography>

        <Box
          sx={{
            py: 2,
          }}
        >
          <Link
            href='#'
            onClick={(e) => {
              e.preventDefault();

              setMoreText(!moreText);
            }}
          >
            {moreText ? 'Show less' : 'Show more'}
          </Link>
        </Box>
        
        <Typography
          variant="p"
          sx={{
            display: moreText ? 'block' : 'none'
          }}
        >
          {moreTextContent}
        </Typography>
      </Box>
      <Box
        sx={{
          py: 4,
          px: [1, 2, 2, 4],
        }}
      >
        <Box
          sx={{
            textAlign: 'right',
            paddingBottom: '20px',
            fontWeight: '800',
          }}
        >
          {`${templatesLength} Templates`}
        </Box>
        {children}
      </Box>
    </Box>
  )
}

SubTemplatePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubTemplatePageLayout
